import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';

const routes: Routes = [
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'recipe',
    loadChildren: () => import('./recipe/recipe.module').then( m => m.RecipeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'weekly-menu',
    loadChildren: () => import('./weekly-menu/weekly-menu.module').then( m => m.WeeklyMenuModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'shopping-list',
    loadChildren: () => import('./shopping-list/shopping-list.module').then( m => m.ShoppingListModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notes',
    loadChildren: () => import('./notes/notes.module').then( m => m.NotesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginModule),
  },
  {
    path: '',
    redirectTo: 'search',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
