import { EventEmitter, Injectable } from "@angular/core";
import { Recipe, UserRecipe } from "src/models/models";
import { DatabaseService } from "./database.service";
import { DataType, UserRecipeBaseService } from "./user-recipe-base.service";
import { ApiService } from "./api.service";
import { RecipeService } from "./recipe.service";
import { UtilsService } from "./utils.service";
import { SessionService } from "./session.service";

@Injectable()
export class UserRecipeService extends UserRecipeBaseService<UserRecipe> {
    DB_VERSION = 5;

    userRecipeChanged = new EventEmitter<{ userRecipe: UserRecipe, recipe: Recipe }>();

    constructor(protected dbService: DatabaseService, protected api: ApiService, private recipeService: RecipeService, protected utils: UtilsService,
            protected sess: SessionService) {
        super(dbService, api, utils, sess);

        super.init('user_recipes', [
            { field: 'recipe_id', type: DataType.NUMBER, primaryKey: true },
            { field: 'rating', type: DataType.NUMBER },
            { field: 'is_mine', type: DataType.BOOLEAN },
            { field: 'is_wished', type: DataType.BOOLEAN },
            { field: 'notes', type: DataType.STRING },
            { field: 'ingredients', type: DataType.STRING },
            { field: 'directions', type: DataType.STRING },
            { field: 'categories', type: DataType.JSON },
        ], 'userRecipes');
    }

    async deleteAndSync() {
        await this.dbService.query(`delete from ${this.table}`);
        await this.syncUserRecipes();
    }

    async resync() {
        if (this.sess.isWeb || !this.sess.isAuthenticated()) return;
        await this.dbService.query(`delete from recipes`);
        super.resync();
    }

    async syncUserRecipes() {
        if (this.sess.isWeb || !this.sess.isAuthenticated()) return;
        let results = await this.api.get<Array<{ recipe: Recipe, userRecipe: UserRecipe }>>(this.apiEndpoint);
        const urchanges = await this.sync(results.map(x => x.userRecipe));
        await this.recipeService.sync(results.map(x => x.recipe));
        return urchanges.map(x => ({
            entity: results.find(y => y.userRecipe.recipe_id == x.entity.recipe_id),
            type: x.type
        }));
    }
}
