export const DAYS = [
    { number: 0, name: 'Sunday' },
    { number: 1, name: 'Monday' },
    { number: 2, name: 'Tuesday' },
    { number: 3, name: 'Wednesday' },
    { number: 4, name: 'Thursday' },
    { number: 5, name: 'Friday' },
    { number: 6, name: 'Saturday' }
];

export enum ChangeType {
    Create,
    Delete,
    Change
};

export enum Progress {
    None,
    InProgress,
    Done,
    Error,
}

export const CONFIG = {
    base: {
        baseUrl: "https://recipes.petjak.com"
    },
    mobile: {
        apiRoot: "http://recipesapi.petjak.com",
    },
    web: {
        apiRoot: "https://recipesapi.petjak.com",
    },
    dev: {
        apiRoot: "http://localhost:3000",
    }
    
}

export const PJ_RECIPES = 'PJ Recipes';
