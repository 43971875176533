import { EventEmitter, Injectable } from "@angular/core";
import { RecipeHistory } from "src/models/models";
import { DatabaseService } from "./database.service";
import { DataType, UserRecipeBaseService } from "./user-recipe-base.service";
import { SessionService } from "./session.service";

@Injectable()
export class RecipeHistoryService extends UserRecipeBaseService<RecipeHistory> {
    DB_VERSION = 2;

    historyChanged = new EventEmitter<any>();

    constructor(protected dbService: DatabaseService, protected sess: SessionService) {
        super(dbService, null, null, sess);

        super.init('recipe_history', [
            { field: 'recipe_id', type: DataType.NUMBER, primaryKey: true },
            { field: 'visited_date', type: DataType.DATETIME },
        ]);
    }
}
