import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DatabaseService } from './services/database.service';
import { ApiService } from './services/api.service';
import { RecipeHistoryService } from './services/recipe-history.service';
import { HttpClientModule } from '@angular/common/http';
import { UserRecipeService } from './services/user-recipe.service';
import { ShoppingListService } from './services/shopping-list.service';
import { RecipeService } from './services/recipe.service';
import { WeeklyMenuService } from './services/weekly-menu.service';
import { CategoryService } from './services/category.service';
import { NotesService } from './services/notes.service';
import { SearchHistoryService } from './services/search-history.service';
import { SessionService } from './services/session.service';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [
		AppComponent,
	],
	entryComponents: [],
	imports: [
		BrowserModule,
		FormsModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: APP_INITIALIZER,
			useFactory: (
				sessionService: SessionService,
				dbService: DatabaseService,
				recipeHistoryService: RecipeHistoryService,
				searchHistoryService: SearchHistoryService,
				userRecipeService: UserRecipeService,
				shoppingListService: ShoppingListService,
				recipeService: RecipeService,
				weeklyMenuService: WeeklyMenuService,
				categoryService: CategoryService,
				notesService: NotesService,
			) => async () => {
				await sessionService.init();
				await dbService.init();
				await recipeHistoryService.initTable();
				await searchHistoryService.initTable();
				await recipeService.initTable();
				await userRecipeService.initTable();
				await shoppingListService.initTable();
				await weeklyMenuService.initTable();
				await categoryService.initTable();
				await notesService.initTable();
			},
			deps: [
				SessionService,
				DatabaseService,
				RecipeHistoryService,
				SearchHistoryService,
				RecipeService,
				UserRecipeService,
				ShoppingListService,
				WeeklyMenuService,
				CategoryService,
				NotesService,
			],
			multi: true
		},
		SessionService,
		ApiService,
		RecipeHistoryService,
		SearchHistoryService,
		UserRecipeService,
		RecipeService,
		ShoppingListService,
		WeeklyMenuService,
		CategoryService,
		NotesService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {

}
