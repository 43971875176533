import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";
import { SessionService } from "./session.service";
import { CONFIG } from "../shared/constants";

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient, private utils: UtilsService, private sess: SessionService) {

    }

    private get apiRoot(): string {
        if (document.cookie.includes('DEV_API')) {
            return CONFIG.dev.apiRoot;
        }
        return this.sess.isWeb ? CONFIG.web.apiRoot : CONFIG.mobile.apiRoot;
    }

    delete<T>(route: string) {
        return this.utils.doPromise(this.http.delete<T>(`${this.apiRoot}/api/${route}`, {
            headers: {
                'access-token': this.sess.getAccessToken()
            }
        }).toPromise());
    }

    async post<T>(route: string, body: any) {
        console.log("POSTING", route, body);
        const res = await this.utils.doPromise(this.http.post<T>(`${this.apiRoot}/api/${route}`, body, {
            headers: {
                'access-token': this.sess.getAccessToken()
            }
        }).toPromise(), route.includes('search'));
        console.log("RES:", res);
        return res;
    }

    get<T>(route: string): Promise<T> {
        return this.http.get<T>(`${this.apiRoot}/api/${route}`, {
            headers: {
                'access-token': this.sess.getAccessToken()
            }
        }).toPromise();
    }
}