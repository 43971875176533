import { EventEmitter, Injectable } from "@angular/core";
import { ShoppingList } from "src/models/models";
import { DatabaseService } from "./database.service";
import { DataType, UserRecipeBaseService } from "./user-recipe-base.service";
import { ApiService } from "./api.service";
import { ChangeType } from "../shared/constants";
import { UtilsService } from "./utils.service";
import { SessionService } from "./session.service";

@Injectable()
export class ShoppingListService extends UserRecipeBaseService<ShoppingList> {
    DB_VERSION = 3;

    shoppingListChanged = new EventEmitter<{ shoppingList: ShoppingList, type: ChangeType }>();

    constructor(protected dbService: DatabaseService, protected api: ApiService, protected utils: UtilsService,
            protected sess: SessionService) {
        super(dbService, api, utils, sess);

        super.init('shopping_lists', [
            { field: 'shopping_list_id', type: DataType.NUMBER, primaryKey: true, autoIncrement: true },
            { field: 'store', type: DataType.STRING },
            { field: 'items', type: DataType.JSON },
        ], 'shoppingLists');
    }
}