import { EventEmitter, Injectable } from "@angular/core";
import { Notes } from "src/models/models";
import { DatabaseService } from "./database.service";
import { DataType, UserRecipeBaseService } from "./user-recipe-base.service";
import { ApiService } from "./api.service";
import { UtilsService } from "./utils.service";
import { ChangeType } from "../shared/constants";
import { SessionService } from "./session.service";

@Injectable()
export class NotesService extends UserRecipeBaseService<Notes> {
    DB_VERSION = 1;

    notesChanged = new EventEmitter<{ notes: Notes, type: ChangeType }>();

    constructor(protected dbService: DatabaseService, protected api: ApiService, protected utils: UtilsService,
            protected sess: SessionService) {
        super(dbService, api, utils, sess);

        super.init('notes', [
            { field: 'notes_id', type: DataType.NUMBER, primaryKey: true, autoIncrement: true },
            { field: 'title', type: DataType.STRING },
            { field: 'notes', type: DataType.STRING },
        ], 'notes');
    }
}