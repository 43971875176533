import { Injectable } from "@angular/core";
import { Device } from "@capacitor/device";

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    isWeb = false;
    constructor() {

    }

    async init() {
        const info = await Device.getInfo();
        this.isWeb = info.platform === 'web' && !localStorage.getItem('development');
    }

    setAccessToken(token: string) {
        localStorage.setItem('access-token', token);
    }

    getAccessToken(): string {
        return localStorage.getItem('access-token') ?? '';
    }

    clearAccessToken() {
        localStorage.removeItem('access-token');
    }

    isAuthenticated() {
        return !!this.getAccessToken();
    }

    // initSession() {
    //     const token = this.getAccessToken();
    //     if (!token) {
    //         return false;
    //     }
    //     return true;
    // }

    getHistory<TEntity>(table: string): TEntity[] {
        // TODO: user specific
        const item = localStorage.getItem(table);
        return item ? JSON.parse(item) : [];
    }

    addHistory<TEntity>(table: string, history: TEntity, keyField: string) {
        // TODO: user specific
        const item = localStorage.getItem(table);
        let curr: Array<TEntity> = item ? JSON.parse(item) : [];
        if (keyField) {
            curr = curr.filter((x) => x[keyField] != history[keyField]);
        }
        curr = [history, ...curr]
        localStorage.setItem(table, JSON.stringify(curr));

    }

    setHistory<TEntity>(table: string, history: TEntity[]) {
        localStorage.setItem(table, JSON.stringify(history));
    }

    clearHistory(table: string) {
        localStorage.removeItem(table);
    }
}