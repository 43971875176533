import { EventEmitter, Injectable } from "@angular/core";
import { Category, UserRecipe } from "src/models/models";
import { DatabaseService } from "./database.service";
import { DataType, UserRecipeBaseService } from "./user-recipe-base.service";
import { ApiService } from "./api.service";
import { UtilsService } from "./utils.service";
import { SessionService } from "./session.service";

@Injectable()
export class CategoryService extends UserRecipeBaseService<Category> {
    DB_VERSION = 1;

    categoriesChanged = new EventEmitter<{ userRecipe: UserRecipe, category: Category }>();

    constructor(protected dbService: DatabaseService, protected api: ApiService, protected utils: UtilsService,
            protected sess: SessionService) {
        super(dbService, api, utils, sess);

        super.init('categories', [
            { field: 'category_id', type: DataType.NUMBER, primaryKey: true, autoIncrement: true },
            { field: 'category_name', type: DataType.STRING },
        ], 'categories');
    }
}