import { Injectable } from "@angular/core";
import { Device } from '@capacitor/device';
import '@capacitor-community/sqlite';
import * as moment from 'moment';
import { CapacitorSQLite, capSQLiteChanges, DBSQLiteValues, SQLiteConnection, SQLiteDBConnection } from '@capacitor-community/sqlite';
import { readString, writeString } from "src/utils/file-helper";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class DatabaseService {
    private SQLITE_WEB_API = 'http://localhost:3001/api';
    private DATABASE = 'user_recipes.db';
    private sqliteConnection: SQLiteDBConnection;
    private isWeb = false;
    private _transaction: boolean;

    
    constructor (private http: HttpClient) {

    }

    async init(): Promise<void> {
        const info = await Device.getInfo();
        if (info.platform === 'web') {
            this.isWeb = true;
            return;
        }

        if (info.platform === 'android') {
            try {
                const sqlite = CapacitorSQLite as any;
                await sqlite.requestPermissions();
            } catch (e) {
                console.log("ERROR:", e);
            }
        }

        CapacitorSQLite.checkConnectionsConsistency({ dbNames: [this.DATABASE] });
        const sqlite = new SQLiteConnection(CapacitorSQLite);
        if (!(await sqlite.isDatabase(this.DATABASE)).result) {
            sqlite.importFromJson(`{
            "database": "${this.DATABASE}",
            "version": 1,
            "encrypted": false,
            "mode": "full"
        }`);
        }
        this.sqliteConnection = await sqlite.createConnection(this.DATABASE, false, "no-encryption", 1);
    }

    async startTransaction() {
        this._transaction = true;
        await this.sqliteConnection.open();
    }

    async endTransaction() {
        this._transaction = false;
        await this.sqliteConnection.close();
    }

    async postDelete(ids: Array<number>) {
        return await this.http.post(`${this.SQLITE_WEB_API}/delete`, { ids }).toPromise();
    }

    private async post(endpoint: string, sql: string, args?: (number | string)[]) {
        console.log("POST:", sql, args);
        return await this.http.post(`${this.SQLITE_WEB_API}/${endpoint}`, { query: sql, params: args }).toPromise();
    }

    private async doRun(sql: string, args?: (number | string)[]): Promise<capSQLiteChanges> {
        console.log("EXEC:", sql, args);
        if (!this._transaction) await this.sqliteConnection.open()
        const res = await this.sqliteConnection.run(sql, args);
        if (!this._transaction) await this.sqliteConnection.close();
        return res;
    }
    
    async run(sql: string, args?: (number | string)[]): Promise<capSQLiteChanges> {
        if (this.isWeb) {
            return await this.post('run', sql, args);
        }
        return await this.doRun(sql, args);
    }

    private async doQuery(sql: string, args?: (number | string)[]): Promise<DBSQLiteValues> {
        const start = moment();
		console.log("QRY:", sql, args);
        if (!this._transaction) await this.sqliteConnection.open()
        const res = await this.sqliteConnection.query(sql, args);
        if (!this._transaction) await this.sqliteConnection.close();
        console.log(`Query took ${moment().diff(start, 'seconds')} seconds`);
        return res;
    }

    async query(sql: string, args?: (number | string)[]): Promise<DBSQLiteValues> {
        if (this.isWeb) {
            return await this.post('query', sql, args);
        }
        return await this.doQuery(sql, args);
    }
}