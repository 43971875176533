import { readString, writeString } from "src/utils/file-helper";
import { DatabaseService } from "./database.service";
import { ApiService } from "./api.service";
import { ChangeType } from "../shared/constants";
import { Field } from "../shared/classes";
import { UtilsService } from "./utils.service";
import { Device } from "@capacitor/device";
import { SessionService } from "./session.service";

export enum DataType {
    NUMBER,
    BOOLEAN,
    DATETIME,
    STRING,
    JSON,
    ARRAY,
}

export abstract class UserRecipeBaseService<TEntity> {
    keyField: string = "";
    table: string;
    apiEndpoint: string;
    fields: Array<Field>;
    DB_VERSION = 0;

    constructor(protected dbService: DatabaseService, protected api: ApiService, protected utils: UtilsService,
        protected sess: SessionService) {
    }

    protected init(
        table: string,
        fields: Array<Field>,
        apiEndpoint?: string) {

        this.table = table;
        this.fields = fields;
        this.keyField = fields.find(x => x.primaryKey).field;
        this.apiEndpoint = apiEndpoint;
    }

    private mapRow(entity: TEntity) {
        for (const fld of this.fields) {
            if (entity[fld.field] !== null) {
                if (fld.type == DataType.BOOLEAN) {
                    entity[fld.field] = entity[fld.field] === 1;
                } else if (fld.type == DataType.JSON) {
                    try {
                        entity[fld.field] = JSON.parse(entity[fld.field]);
                    } catch (_) {
                        entity[fld.field] = {};
                    }
                } else if (fld.type == DataType.ARRAY) {
                    entity[fld.field] = entity[fld.field].split(',');
                }
            }
        }
        return entity;
    }

    async getAll(qry?: string, args?: Array<string | number>): Promise<TEntity[]> {
        if (this.sess.isWeb) return [];
        const actqry = qry || `SELECT ${this.fields.map((x) => x.field).join(',')} FROM ${this.table}`;
        const res = await this.dbService.query(actqry, args);
        return res.values?.map((x) => this.mapRow(x));
    }

    async getByKey(key: number): Promise<TEntity | null> {
        const actqry = `SELECT ${this.fields.map((x) => x.field).join(',')} FROM ${this.table} WHERE ${this.keyField} = ?`;
        const res = await this.dbService.query(actqry, [key]);
        if (res.values?.length) {
            return this.mapRow(res.values[0]);
        }
        return null;
    }

    async delete(entities: TEntity[]) {
        if (this.sess.isWeb) return;
        const qry = `DELETE FROM ${this.table} WHERE ${this.keyField} in (${entities.map(x => '?').join(',')})`;
        await this.dbService.run(qry, entities.map(x => x[this.keyField]));
    }

    async deleteAll(): Promise<void> {
        if (this.sess.isWeb) return;
        const qry = `DELETE FROM ${this.table}`;
        await this.dbService.run(qry);
    }

    private getFieldValue = (field: Field, entity: any): any => {
        if (entity[field.field] === null || entity[field.field] === undefined) return null;
        if (field.type === DataType.NUMBER || field.type === DataType.DATETIME) return entity[field.field];
        if (field.type === DataType.BOOLEAN) return entity[field.field] ? 1 : 0;
        if (field.type === DataType.JSON) return JSON.stringify(entity[field.field]);
        if (field.type === DataType.ARRAY) return entity[field.field].join(',');
        return entity[field.field];
    }

    async createUpdate(entity: TEntity): Promise<TEntity> {
        if (this.sess.isWeb) return;
        const qry = `INSERT OR REPLACE INTO  ${this.table} (${this.fields.map((x) => x.field)})
        VALUES (${this.fields.map(_ => '?').join(',')})`;
        const args = this.fields.map((x) => this.getFieldValue(x, entity));
        const res = await this.dbService.run(qry, args);
        entity[this.keyField] = res.changes?.lastId;
        return entity;
    }

    private getFieldForType(type: DataType): string {
        switch (type) {
            case DataType.BOOLEAN:
                return 'INTEGER';
            case DataType.DATETIME:
                return 'BIGINT';
            case DataType.NUMBER:
                return 'INTEGER';
            case DataType.STRING:
            case DataType.JSON:
            case DataType.ARRAY:
                return 'VARCHAR';
        }
    }

    private async createTable() {
        const colCreates = [];
        for (const fld of this.fields) {
            colCreates.push(`${fld.field} ${this.getFieldForType(fld.type)}${fld.primaryKey ? ' PRIMARY KEY' : ''}${fld.autoIncrement ? ' AUTOINCREMENT' : ''}`);
        }
        try {
            await this.dbService.run(`DROP TABLE ${this.table}`);
        } catch (e) {

        }
        await this.dbService.run(`CREATE TABLE ${this.table} (
            ${colCreates.join(',')}
        )`)
    }

    async initTable(): Promise<void> {
        if (this.sess.isWeb) {
            return;
        }

        const userVersionFile = `${this.table}_db_version.txt`;
        let dropUR = false;
        try {
            const version = await readString(userVersionFile);
            if (version !== this.DB_VERSION.toString()) {
                dropUR = true;
            }
        } catch (e) {
            dropUR = true;
        }

        if (!dropUR) return;

        console.log(`REWRITING user table ${this.table}`);
        await this.createTable();
        await writeString(userVersionFile, this.DB_VERSION.toString());
    }


    private async syncWithDb(ae: TEntity, dbe: TEntity) {
        const somethingChanged = this.fields.some(x => {
            if (!ae[x.field] && !dbe[x.field]) return false;

            if (x.type == DataType.BOOLEAN) {
                return (!!ae[x.field]) != (!!dbe[x.field]);
            }
            if (x.type == DataType.JSON) {
                return JSON.stringify(ae[x.field]) != dbe[x.field];
            }
            if (x.type == DataType.ARRAY) {
                return ae[x.field].join(',') == dbe[x.field].join(',');
            }
            return ae[x.field] != dbe[x.field];
        });
        if (somethingChanged) {
            await this.createUpdate(ae);
        }
    }

    async deleteAndSync() {
        await this.dbService.query(`delete from ${this.table}`);
        await this.sync();
    }

    async resync() {
        if (this.sess.isWeb) return;
        await this.utils.doFunc(async () => {
            await this.deleteAndSync();
        });
    }

    async sync(apiEntities?: Array<TEntity>) {
        if (this.sess.isWeb) return [];
        if (!this.apiEndpoint && !apiEntities) return [];
        if (!apiEntities) apiEntities = await this.api.get<TEntity[]>(this.apiEndpoint);
        const dbEntities: Array<TEntity> = (await this.dbService.query(`select * from ${this.table}`)).values;
        for (const ae of apiEntities) {
            const dbe = dbEntities.find(x => x[this.keyField] == ae[this.keyField]);
            if (dbe) {
                await this.syncWithDb(ae, dbe);
            } else {
                await this.createUpdate(ae);
            }
        }
        const toDelete: Array<TEntity> = [];
        for (const dbe of dbEntities) {
            const ae = apiEntities.find(x => x[this.keyField] == dbe[this.keyField]);
            if (!ae) {
                toDelete.push(dbe);
            }
        }
        if (toDelete.length) {
            await this.delete(toDelete);
        }
    }
}
