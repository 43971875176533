import { Injectable } from "@angular/core";
import { WeeklyMenu } from "src/models/models";
import { DatabaseService } from "./database.service";
import { DataType, UserRecipeBaseService } from "./user-recipe-base.service";
import { ApiService } from "./api.service";
import { UtilsService } from "./utils.service";
import { SessionService } from "./session.service";

@Injectable()
export class WeeklyMenuService extends UserRecipeBaseService<WeeklyMenu> {
    DB_VERSION = 0;

    constructor(protected dbService: DatabaseService, protected api: ApiService, protected utils: UtilsService,
            protected sess: SessionService) {
        super(dbService, api, utils, sess);

        super.init('weekly_menu', [
            { field: 'day_number', type: DataType.NUMBER, primaryKey: true },
            { field: 'recipe_ids', type: DataType.ARRAY },
        ], 'shoppingLists');
    }
}