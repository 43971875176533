import { Injectable } from "@angular/core";
import { Recipe } from "src/models/models";
import { DatabaseService } from "./database.service";
import { DataType, UserRecipeBaseService } from "./user-recipe-base.service";
import { ApiService } from "./api.service";
import { UtilsService } from "./utils.service";
import { SessionService } from "./session.service";

@Injectable()
export class RecipeService extends UserRecipeBaseService<Recipe> {
    DB_VERSION = 3;
    
    constructor(protected dbService: DatabaseService, protected api: ApiService, protected utils: UtilsService,
            protected sess: SessionService) {
        super(dbService, api, utils, sess);

        super.init('recipes', [
            { field: 'recipe_id', type: DataType.NUMBER, primaryKey: true },
            { field: 'recipe_name', type: DataType.STRING },
            { field: 'ingredients', type: DataType.STRING },
            { field: 'directions', type: DataType.STRING },
            { field: 'url', type: DataType.STRING },
            { field: 'source_id', type: DataType.NUMBER },
            { field: 'source_name', type: DataType.STRING },
            { field: 'image_urls', type: DataType.STRING },
            { field: 'rating', type: DataType.NUMBER },
            { field: 'rating_count', type: DataType.NUMBER },
            { field: 'copied_from_id', type: DataType.NUMBER },
        ]);
    }
}