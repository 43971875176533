import { EventEmitter, Injectable } from "@angular/core";
import { SearchHistory } from "src/models/models";
import { DatabaseService } from "./database.service";
import { DataType, UserRecipeBaseService } from "./user-recipe-base.service";
import { SessionService } from "./session.service";

@Injectable()
export class SearchHistoryService extends UserRecipeBaseService<SearchHistory> {
    DB_VERSION = 3;

    historyChanged = new EventEmitter<any>();

    constructor(protected dbService: DatabaseService, protected sess: SessionService) {
        super(dbService, null, null, sess);

        super.init('search_history', [
            { field: 'search_params', type: DataType.STRING },
            { field: 'search_date', type: DataType.NUMBER, primaryKey: true },
        ]);
    }
}
